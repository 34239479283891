import React from "react";
import * as singleSpa from "single-spa";
import { useTranslation } from "react-i18next";
import { ChevronRightIcon } from "gx-npm-icons";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "./navigation-links-v2.styles.module.scss";
import { NAV_MARKET, NAV_MARKETS } from "../../../../lib/constants";
import { colorPalette } from "gx-npm-common-styles";

type NavigationLinksProps = {
  templateId: number;
  templateName: string;
  productName?: string;
};
const NavigationLinksV2: React.FC<NavigationLinksProps> = ({ templateId = 0, templateName = "", productName = "" }) => {
  const handleTemplateHubNav = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    singleSpa.navigateToUrl(NAV_MARKETS);
  };

  const handleMarketProfileNav = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    singleSpa.navigateToUrl(`${NAV_MARKET}/${templateId}`);
  };
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <div>
        <TextLink
          href={NAV_MARKETS}
          onClick={(e: React.FormEvent<HTMLInputElement>) => handleTemplateHubNav(e)}
          rootClassName={"gx-product-preview-template-hub-nav"}
          text={t("Markets")}
        />
      </div>
      <ChevronRightIcon fill={colorPalette.neutrals.pewter.hex} />
      <div>
        <TextLink
          href={`${NAV_MARKET}/${templateId}/preview`}
          onClick={(e: React.FormEvent<HTMLInputElement>) => handleMarketProfileNav(e)}
          rootClassName={"gx-product-preview-template-preview-nav"}
          text={templateName}
        />
      </div>
      <ChevronRightIcon fill={colorPalette.neutrals.pewter.hex} />
      <TypographyComponent rootClassName={styles.productName} color={"carbon"} styling={"p3"} boldness={"medium"}>
        {productName}
      </TypographyComponent>
    </section>
  );
};

export default NavigationLinksV2;
