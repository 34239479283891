// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.czv_akBDTJzHoCW8VjPZ{padding-right:81px}.czv_akBDTJzHoCW8VjPZ .lKSHxmvsU0EC7N71vIyd{padding-bottom:56px}.czv_akBDTJzHoCW8VjPZ .m5Bi62AuDjBOX3rCTLqp{display:flex;align-items:center;gap:15px;padding-bottom:12px}.czv_akBDTJzHoCW8VjPZ .c5XZdJXg8xs8uwgGi0_2{color:var(--color-neutrals-quartz);width:400px}.czv_akBDTJzHoCW8VjPZ .xL2IopsG30zbspse9bAu{width:400px;margin-bottom:56px}.czv_akBDTJzHoCW8VjPZ .WQ8EAo93gVuhZikrhKdJ{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.czv_akBDTJzHoCW8VjPZ .FAN2B39qh3qE8puG2ecj{margin-bottom:16px}.czv_akBDTJzHoCW8VjPZ .JJMGm1bozii9i0INIKQ0 .UgNQsOD4AIvgC2ddNas1{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/researchSlideIn/components/market-guide-body.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CACE,mBAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kCAAA,CACA,WAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CAEF,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAEF,4CACE,kBAAA,CAGA,kEACE,WAAA","sourcesContent":[".contentBodyMidSectionLeft {\n  padding-right: 81px;\n  & .gx-gartner-mg-desc-text {\n    padding-bottom: 56px;\n  }\n  .contentBodyMidSectionTitleText {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  .contentBodyMidSectionDescText {\n    color: var(--color-neutrals-quartz);\n    width: 400px;\n  }\n\n  .contentBodyMidSectionDescTextGCOM3523 {\n    width: 400px;\n    margin-bottom: 56px;\n  }\n  .contentLearnMoreLine {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n  .contentBodyMidSectionLearnMore {\n    margin-bottom: 16px;\n  }\n  .contentLink {\n    .contentBodyMidSectionLinkText {\n      width: 320px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBodyMidSectionLeft": `czv_akBDTJzHoCW8VjPZ`,
	"gx-gartner-mg-desc-text": `lKSHxmvsU0EC7N71vIyd`,
	"contentBodyMidSectionTitleText": `m5Bi62AuDjBOX3rCTLqp`,
	"contentBodyMidSectionDescText": `c5XZdJXg8xs8uwgGi0_2`,
	"contentBodyMidSectionDescTextGCOM3523": `xL2IopsG30zbspse9bAu`,
	"contentLearnMoreLine": `WQ8EAo93gVuhZikrhKdJ`,
	"contentBodyMidSectionLearnMore": `FAN2B39qh3qE8puG2ecj`,
	"contentLink": `JJMGm1bozii9i0INIKQ0`,
	"contentBodyMidSectionLinkText": `UgNQsOD4AIvgC2ddNas1`
};
export default ___CSS_LOADER_EXPORT___;
